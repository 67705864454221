import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, Box, Modal, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Redo } from '@mui/icons-material';
import paymentIcon from "../../assests/icons/payments.png";
import { useNavigate } from 'react-router-dom';
import easypaisa from "../../assests/icons/eas.png";
import cash from "../../assests/icons/delivery.png";
const CartPage = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updatedQuantity, setUpdatedQuantity] = useState(1);
  const [updatedColor, setUpdatedColor] = useState('');
  const token = useSelector((state) => state.auth.token);

  // Fetch cart items from API
  const fetchCartItems = async () => {
    try {
      const response = await axios.get('https://arshymuala.com/api/cart/get', 
        {
            headers: {
              Authorization: `Bearer ${token}`, // Add token in headers
            },
        }
      );
      setCartItems(response.data.data.lineItems);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setCartItems([]); // Set empty array if 404 is received
      } else {
        console.error('Error fetching cart items:', error);
      }
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  // Handle removing an item
  const handleRemove = async (id, color) => {
    try {
      await axios.delete('https://arshymuala.com/api/cart/delete', {
        data: { productId: id, color }, // Include data as part of the request body
        headers: {
          Authorization: `Bearer ${token}`, // Add token in headers
        },
      });
      
      // Fetch updated cart items after removing the item
      fetchCartItems();
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };
  
  // Handle opening modal for editing item
  const handleEdit = (item) => {
    setSelectedItem(item);
    setUpdatedQuantity(item.quantity);
    setUpdatedColor(item.color);
    setModalOpen(true);
  };

  // Handle updating the item
  const handleUpdate = async () => {
    if (!selectedItem) return;

    try {
      await axios.put('https://arshymuala.com/api/cart/update', {
        productId: selectedItem._id,
        quantity: updatedQuantity,
        color: updatedColor,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add token in headers
        },
      });
      fetchCartItems(); // Refresh items
      setModalOpen(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  // Handle Stripe session API call
  const handleContinueShopping = async () => {
    const payload = {
      line_items: cartItems.map(item => ({
        price_data: {
          currency: 'usd',
          product_data: {
            name: item.productName,
            metadata: {
              color: item.color,
              size: item.size,
            },
          },
          unit_amount: item.unitAmount,
        },
        quantity: item.quantity,
      })),
    };

    try {
      const response = await axios.post('https://arshymuala.com/api/strip/sessions', payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token in headers
        },
      });
      const { stripeUrl } = response.data;

      // Redirect to Stripe checkout
      if (stripeUrl) {
        window.location.href = stripeUrl;
      }
    } catch (error) {
      console.error('Error creating Stripe session:', error);
    }
  };


  return (
    <React.Fragment>
    <Navbar/>
    <Box py={4} px={{xs: 1, md:10}}>

      <Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: 2}}>
      <Button endIcon={<img src={paymentIcon} alt="payments" style={{ maxWidth: '25px' }} />} 
              sx={{ mt: 2, color: '#fff', fontSize: 16, fontWeight: 'bold', textDecoration: 'underline', color: '#D32F2F',
              '&:hover': { textDecoration: 'underline' } }} 
              onClick={()=> navigate("/orders")}>
        View Orders
      </Button>
      </Box>
      
      {cartItems.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Item</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Subtotal</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Edit</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <img src={item.image} alt={item.productName} width="50" height="50" />
                    <div style={{ fontWeight: 'bold' }}>{item.productName}</div>
                    <div style={{ color: 'grey' }}>Size: {item.size}</div>
                    <div style={{ color: 'grey' }}>Color: {item.color}</div>
                  </TableCell>
                  <TableCell>PKR {item.unitAmount.toFixed(2)}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>PKR {(item.unitAmount * item.quantity).toFixed(2)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon color="#4A403A" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemove(item._id, item.color)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography style={{ color: 'red', fontWeight: 'bold', marginTop: 10, textAlign: 'center' }}>
          No cart found.
        </Typography>
      )}
       

      {cartItems.length > 0 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', flexDirection:"column",alignItems:"center",  gap: "4px" }}>
          <Button endIcon={<Redo/>} sx={{ mt: 2,padding:"15px 10px", color: '#fff', fontWeight: 'bold',width:"300px", background: '#646EDE', px: 2,
          '&:hover': { background: '#646EDE' } }} onClick={handleContinueShopping}>
            Debit Cards
          </Button>
          <Button endIcon={                <img src={easypaisa} alt="payments" style={{ maxWidth: '66px', maxHeight: '95px'  }} />
} sx={{ mt: 2, color: '#fff', fontWeight: 'bold',width:"300px", background: '#F7F7F7', px: 2,
          '&:hover': { background: '#F7F7F7' } }} >
           <a href=" https://wa.me/+971556203945
" style={{ textDecoration:"none", color:"#25B05C"}}>Payment Using</a>  
          </Button>
          <Button endIcon={ <img src={cash} alt="payments" style={{ maxWidth: '66px', maxHeight: '47px'  }} />
} sx={{ mt: 2, color: '#FFFFFF', fontWeight: 'bold',width:"300px", background: '#F57C00',
          '&:hover': { background: ' #F57C00 ' } }} >
           <a href=" https://wa.me/+971556203945
           " style={{ textDecoration:"none", color:"#FFFFFF"}}>Cash on Delivery</a>  
          </Button>
        </Box>
       
      )}
      {/* Edit Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box p={3} bgcolor="white" borderRadius={2} style={{ maxWidth: '400px', margin: 'auto', marginTop: '10%' }}>
          <h2>Edit Item</h2>
          <TextField
            label="Quantity"
            type="number"
            value={updatedQuantity}
            onChange={(e) => setUpdatedQuantity(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Color"
            value={updatedColor}
            onChange={(e) => setUpdatedColor(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleUpdate} fullWidth>
            Update Item
          </Button>
        </Box>
      </Modal>
    </Box>
    <Footer/>
    </React.Fragment>
  );
};

export default CartPage;
