import React from 'react';
import AppRouting from "./Routing/AppRouting";

const App = () => {
  return (
    <>
       <AppRouting />
    </>
  )
}

export default App






