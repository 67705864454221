import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Box } from '@mui/material';
import { Email, Phone } from '@mui/icons-material';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    setSuccess(false);

    const formData = {
      name,
      email,
      message,
    };

    try {
      const response = await fetch('https://formspree.io/f/xqazorvk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setName('');
        setEmail('');
        setMessage('');
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (err) {
      setError('Failed to send your message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
       <Helmet>
        {/* Primary SEO Tags */}
        <title>Contact Us | ArshyMaula</title>
        <meta 
          name="description" 
          content="Get in touch with ArshyMaula for any inquiries or support. We're here to help with your fashion needs!" 
        />
        <meta 
          name="keywords" 
          content="Contact ArshyMaula, customer support, inquiries, fashion help, clothing customer service" 
        />
        
        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Contact Us | ArshyMaula" />
        <meta 
          property="og:description" 
          content="Reach out to Arshy Maula for any inquiries or support. We're dedicated to assisting you with your fashion needs." 
        />
        <meta property="og:url" content="https://www.arshymaula.com/contactUs" />
        <meta property="og:type" content="website" />
        
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.arshymaula.com/contactUs" />
        
        {/* Additional SEO Optimizations */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Navbar />
      <Box sx={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}>
        <Typography variant="h4" align="center" gutterBottom sx={{
          fontFamily: 'Frank Ruhl Libre',
          fontSize: { xs: 35, md: 60 },
          fontWeight: 900,
          lineHeight: { xs: '30px', md: '60px' },
          color: '#4A403A',
          letterSpacing: '2%',
        }}>
          Contact Us
        </Typography>

        {/* Form Submission */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} mt={3}>
            {/* Name Input */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Enter Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>

            {/* Email Input */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Enter Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: <Email sx={{ marginRight: '8px', color: 'gray' }} />,
                }}
                required
              />
            </Grid>

            {/* Message Input */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#6C4A33',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#6C4A33',
                  },
                }}
                disabled={loading}
              >
                {loading ? 'SENDING...' : 'SEND'}
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* Success Message */}
        {success && (
          <Typography color="success" align="center" sx={{ marginTop: '1rem' }}>
            Your message was successfully sent!
          </Typography>
        )}

        {/* Error Message */}
        {error && (
          <Typography color="error" align="center" sx={{ marginTop: '1rem' }}>
            {error}
          </Typography>
        )}

        {/* Contact Details */}
        <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
          <Typography variant="body1" sx={{
            textAlign: "center",
            fontSize: { xs: 15, md: 18 },
            fontWeight: 900,
            lineHeight: { xs: '20px', md: '35px' },
            color: '#4A403A',
            letterSpacing: '2%',
          }}>
            NEED HELP?
          </Typography>

          <Grid container justifyContent="center" spacing={4} mt={0.5}>
            {/* Phone Contact */}
            <Grid item>
              <Phone sx={{ verticalAlign: 'middle', marginRight: '4px', color: 'grey' }} />
              +971 55 620 3945
            </Grid>

            {/* Email Contact */}
            <Grid item>
              <Email sx={{ verticalAlign: 'middle', marginRight: '4px', color: 'grey' }} />
              Contactus@arshymuala.com
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
