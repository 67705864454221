import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Grid, Box, MenuItem, FormControl, Select, InputLabel, Typography } from "@mui/material";
import ProductCard from "../../components/ProductCard/ProductCard";
import coverImage from "../../assests/images/cover-image.png"; // Default cover image
import allCategory from "../../assests/images/All-Category.png";
import menCategory from "../../assests/images/mensCategory.jpg";
import axios from "axios";
// import { Helmet } from "react-helmet"; // To manage SEO-related metadata
import { useLocation } from "react-router-dom";

const Products = ({ initialCategory }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collection = queryParams.get("collection");

  const [category, setCategory] = useState("all");
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setCategory(initialCategory); // Set initial category from prop
  }, [initialCategory]);

  useEffect(() => {
    if (collection) {
      fetchProductsByCollection(collection);
      setCategory("all");
    } else {
      setCategory(initialCategory);
      fetchProducts(); // Fetch default products
    }
  }, [location.search, initialCategory]);

  // Fetch products by collection
  const fetchProductsByCollection = async (collectionName) => {
    try {
      const response = await axios.get(
        `https://arshymuala.com/api/product/getProductByCollection?collection=${collectionName}`
      );
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching products by collection:", error);
    }
  };

  // Fetch all products
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://arshymuala.com/api/product/getAll');
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Filter products by category
  const filteredProducts = category === "all" 
    ? productData 
    : productData.filter(product => product.category === category);

  // Select cover image based on category
  const coverImageSelection = category === "Men" 
    ? menCategory 
    : category === "Women" 
    ? coverImage 
    : allCategory;

  // Generate dynamic page title for SEO
  const pageTitle = category === "all" 
    ? "All Collections - Shop the Latest Trends" 
    : `${category} Collection - Shop the Latest ${category} Fashion`;

  //   <Helmet>
  //   <title>{pageTitle}</title>
  //   <meta name="description" content={`Discover the latest ${category} fashion collection at our online store. Shop a wide variety of products for men, women, and more.`} />
  //   <meta name="keywords" content={`fashion, ${category.toLowerCase()} fashion, online store, latest trends, collections`} />
  //   <link rel="canonical" href="https://www.arshymaula.com/products" />

  // </Helmet>
  return (
    <React.Fragment>
     

      <Navbar />

      {/* Cover Image */}
      <Box 
        component="img"
        src={coverImageSelection}
        alt={`${category} Collection Cover`} 
        sx={{ width: "100%", height: "auto" }} 
      />

      {/* Page Heading */}
      <Box sx={{ textAlign: "center", padding: { xs: 2, md: 6 } }}>
        <Typography variant="h1" sx={{ fontSize: { xs: "24px", md: "48px" }, fontWeight: "bold", color: "#333" }}>
          {category === "all" ? "Explore Our Full Collection" : `${category} Collection`}
        </Typography>
        <Typography variant="h2" sx={{ fontSize: { xs: "16px", md: "24px" }, color: "#666", marginTop: "8px" }}>
          Shop the latest trends in fashion
        </Typography>
      </Box>

      {/* Filter Dropdown */}
      <Box sx={{ flexGrow: 1, padding: { xs: 2, md: 6 } }}>
        <Box display={"flex"} justifyContent={{ xs: 'center', md: 'flex-end' }} px={{ xs: 0, md: 5 }}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              value={category}
              label="Filter"
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Men">Men</MenuItem>
              <MenuItem value="Women">Women</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Product Grid */}
        <Grid container spacing={3} mt={2}>
          {filteredProducts.map((product, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ProductCard {...product} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer />
    </React.Fragment>
  );
};

export default Products;
