import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, ButtonGroup, useMediaQuery, useTheme, IconButton, Card, CardMedia, CardContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import addToCartSideImage from "../../assests/images/Add-to-cart-image.png";
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { NavigateBefore, NavigateNext, RemoveRedEyeOutlined } from '@mui/icons-material';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import clothes from "../../assests/images/cloths.png";
import Slider from 'react-slick';
import mens1 from "../../assests/images/men1.png"
import mens2 from "../../assests/images/men2.png"
import mens3 from "../../assests/images/men3.png"
import mens4 from "../../assests/images/men4.png";
import womens1 from "../../assests/images/women1.png";
import womens2 from "../../assests/images/women2.png";
import womens3 from "../../assests/images/women3.png";
import womens4 from "../../assests/images/women4.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';


// const products = [
//     {
//       title: 'Pastel Sleeveless',
//       image: mens1,
//       originalPrice: '$220',
//       discountedPrice: '$140',
//       rating: 5,
//     },
//     {
//       title: 'Printed Collar Shirt',
//       image: womens1,
//       originalPrice: '$210',
//       discountedPrice: '$110',
//       rating: 4,
//     },
//     {
//       title: 'Cardigan',
//       image: mens2,
//       originalPrice: '$290',
//       discountedPrice: '$210',
//       rating: 4,
//     },
//     // Add more products as needed
//   ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 images on desktop
    slidesToScroll: 1,
    nextArrow: <NavigateNext sx={{color: '#ffff', background:'#4A403A', borderRadius: 10,
      '&:hover': {
        color: '#ffff', background:'#4A403A', borderRadius: 10,
      }
    }}/>,
    prevArrow: <NavigateBefore sx={{color: '#ffff', background:'#4A403A', borderRadius: 10,
      '&:hover': {
        color: '#ffff', background:'#4A403A', borderRadius: 10,
      }
    }}/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // 3 slides on medium screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // 2 slides on tablet
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  

const ProductSection = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const { _id, title, price, image  } = location.state || {};

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // State management
  const [selectedSize, setSelectedSize] = useState('M');
  const [selectedColor, setSelectedColor] = useState('Grey');
  const [quantity, setQuantity] = useState(1);
  const basePrice = price; // Base price of the product
  const [totalPrice, setTotalPrice] = useState(basePrice);

  const [products, setProducts] = useState([]);
  const token = useSelector((state) => state.auth.token);


  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top of the page when the component renders
  }, [title]);
  // Handle size selection
  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };
  const fetchProducts = async () => {
    try {
        const response = await axios.get('https://arshymuala.com/api/product/getTopRatedProducts');
        setProducts(response.data);
    } catch (error) {
        console.error("Error fetching products:", error);
    }
};
  useEffect(() => {

    fetchProducts();


  }, []);

  // Handle color selection
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  // Handle quantity change
  const handleQuantityChange = (operation) => {
    setQuantity((prevQuantity) => {
      const newQuantity = operation === 'increment' ? prevQuantity + 1 : prevQuantity > 1 ? prevQuantity - 1 : 1;
      setTotalPrice(newQuantity * basePrice); // Update the total price based on the new quantity
      return newQuantity;
    });
  };

  // Handle Add to Cart button click
  const handleAddToCart = async () => {
    // const productDetails = {
    //   size: selectedSize,
    //   color: selectedColor,
    //   quantity: quantity,
    //   unitAmount: basePrice,
    //   image: image,
    //   totalPrice: totalPrice,
    // };
    // console.log('Product added to cart:', productDetails);
    if(!token){
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: 'Please signup or login for add to cart',
        showCancelButton: false,
        confirmButtonText: 'OK',
        // cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        
        
          navigate("/signin");
         
      } else {
        // User clicked Cancel or closed the popup
        // Handle accordingly
      }
  });
    }


    try {
      const response = await axios.post('https://arshymuala.com/api/cart/add', {
       quantity : quantity ,
  color: selectedColor,
  unitAmount: basePrice,
  size: selectedSize,
  image: image, 
   title: title
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add token in headers
        },
      });
      if(response?.data?.message === "Product already exists in your cart. Please update the item on the cart page.")
      {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: 'Product already exists in your cart. Please update the item on the cart page.',
          showCancelButton: false,
          confirmButtonText: 'OK',
          // cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          
          
            navigate("/carts");
           
        } else {
          // User clicked Cancel or closed the popup
          // Handle accordingly
        }
    });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: 'Product added successfully in your cart. You can view the item on the cart page.',
          showCancelButton: false,
          confirmButtonText: 'OK',
          // cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          
          
            navigate("/carts");
           
        } else {
          // User clicked Cancel or closed the popup
          // Handle accordingly
        }
    });
      }
       
    } catch (error) {
      console.error('Error updating item:', error);
    }
   
    // You can proceed with adding the product to the cart, sending it to a backend, etc.
  };

  const handleClickTopPicksCards = (event, product) => {
    event.preventDefault();
    console.log(product)
    const _id = product?._id;     //for product id
    const title = product?.title; //for title 
    const price = product?.price; //for price
    const image = product?.image; // for image
    alert(title)
    navigate("/productSection", { state: {_id, title, price, image} } );

  };



  return (

    <React.Fragment>
        <Navbar/>
<Box py={{xs: 4, md:7}}>
  <Grid container spacing={2} alignItems="center" justifyContent="center">
    {/* Image Section */}
    <Grid item xs={12} md={6} display="flex" justifyContent="center">
      <Box
        component="img"
        src={image} // replace with actual image URL
        alt="Product Image"
        sx={{ width: { xs: '100%', md: '550px' }, height: { xs: 'auto', md: '580px' }, borderRadius: 0 }}
      />
    </Grid>

    {/* Product Details Section */}
    <Grid item xs={12} md={6} ml={{xs: 2, md: 0}}>
      <Typography
        gutterBottom
        sx={{
          fontFamily: 'Frank Ruhl Libre',
          fontSize: { xs: 24, md: 35 },
          fontWeight: 900,
          color: '#4A403A',
          letterSpacing: '2%',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: { xs: 24, md: 35 },
          fontWeight: 600,
          color: '#4A403A',
          letterSpacing: '2%',
        }}
      >
        PKR{totalPrice?.toFixed(2)}
      </Typography>
      <Box display="flex" alignItems="center" flexDirection="row">
        <RemoveRedEyeOutlined sx={{ color: "#8A8888" }} />
        <Typography variant="body2" color="#8A8888" gutterBottom ml={1} mt={1} fontFamily="Roboto Slab" fontWeight={500} fontSize={18}>
          24 People are viewing this right now
        </Typography>
      </Box>
      <Typography variant="body2" color="#8A8888" gutterBottom mt={2} fontFamily="Roboto Slab" fontWeight={400} fontSize={17}>
        Only 9 item(s) left in stock!
      </Typography>

      {/* Size Selector */}
      <Box sx={{ marginY: 2 }}>
        <Typography variant="subtitle1"
          sx={{
            fontFamily: 'Lato',
            fontSize: { xs: 24, md: 35 },
            fontWeight: 500,
            lineHeight: { xs: '30px', md: '54px' },
            color: '#4A403A',
            letterSpacing: '2%',
          }}>Size: {selectedSize}</Typography>
        <ButtonGroup variant="outlined" sx={{ marginTop: 1 }}>
          {['S', 'M', 'L'].map((size) => (
            <Button
              key={size}
              variant={selectedSize === size ? 'contained' : 'outlined'}
              sx={{
                backgroundColor: selectedSize === size ? '#4A403A' : '#EEECEC',
                border: '2px solid #4A403A',
                fontSize: 20,
                color: selectedSize === size ? '#fff' : '#4A403A',
                '&:hover': {
                  backgroundColor: selectedSize === size ? '#4A403A' : '#EEECEC',
                  border: '2px solid #4A403A',
                  color: selectedSize === size ? '#fff' : '#4A403A',
                }
              }}
              onClick={() => handleSizeSelect(size)}
            >
              {size}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {/* Color Selector */}
      <Box sx={{ marginY: 3 }}>
        <Typography variant="subtitle1" sx={{
          fontFamily: 'Lato',
          fontSize: { xs: 20, md: 30 },
          fontWeight: 500,
          lineHeight: { xs: '30px', md: '54px' },
          color: '#4A403A',
          letterSpacing: '2%',
          textTransform: 'capitalize'
        }}>Color: {selectedColor}</Typography>
        <Box sx={{ display: 'flex', gap: 2, marginTop: 2.5 }}>
          {['green', 'grey', 'yellow'].map((color) => (
            <Box
              key={color}
              onClick={() => handleColorSelect(color)}
              sx={{
                width: selectedColor === color ? 40 : 30,
                    height: selectedColor === color ? 40 : 30,
                backgroundColor: color.toLowerCase(),
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Quantity Selector */}
      <Box display="flex" alignItems={{xs:"start", md: 'center'}} gap={6} flexDirection={{ md: 'row', xs: 'column' }}>
        <Box sx={{ marginY: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ButtonGroup variant="outlined" sx={{ alignItems: 'center' }}>
            <IconButton onClick={() => handleQuantityChange('decrement')}>
              <RemoveIcon sx={{ fontWeight: 'bold' }} />
            </IconButton>
            <Typography sx={{ padding: '0 16px', fontWeight: 'bold', color: '#4A403A' }}>{quantity}</Typography>
            <IconButton onClick={() => handleQuantityChange('increment')}>
              <AddIcon sx={{ fontWeight: 'bold' }} />
            </IconButton>
          </ButtonGroup>
        </Box>

        {/* Add to Cart Button */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<ShoppingCartIcon />}
          sx={{
            padding: { xs: '6px 18px', md: '8px 25px' },
            borderRadius: 0,
            background: '#4A403A',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
              background: '#4A403A',
              color: 'white',
            }
          }}
          onClick={handleAddToCart}
        >
          Add to Cart
        </Button>
      </Box>
    </Grid>
  </Grid>
</Box>


<Box  sx={{  padding: {xs: '50px 40px', md: '50px 60px'}}} >
      <Grid container spacing={2} alignItems="center" justifyContent="center" >
        
        <Grid item xs={12} md={12} >
            <Typography   sx={{
              textAlign: 'center',
                  fontFamily: 'Frank Ruhl Libre',
                  fontSize: { xs: 29, md: 43 },
                  fontWeight: 900,
                  lineHeight: { xs: '50px', md: '87.79px' },
                  color: '#4A403A',
                  letterSpacing: '2%',
                }}>
            Similar Product you Like
            </Typography>

            <Slider {...settings} style={{ marginTop: '30px' }}>
            { products.length > 0 && products.map((product, index) => (
              <Card key={index} sx={{ margin: '0 10px', padding: '10px',  boxShadow: 'none', cursor: 'pointer' }}
              onClick={(event) => handleClickTopPicksCards(event, product)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={product.image}
                  alt={product.title}
                />
                <CardContent sx={{background: '#D9D9D9' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1, color: 'orangered' }}>
                    {'★'.repeat(Math.floor(product.rating))}
                    {product.rating % 1 !== 0 && '½'}
                  </Box>
                  <Typography variant="h6" color="#4A403A">{product.title}</Typography>
                  <Typography variant="body2" color="#4A403A" >
                    {product.originalPrice} <span style={{ textDecoration: 'line-through' }}>{product.discountedPrice}</span>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Box>

<Footer/>
    </React.Fragment>


  );
};

export default ProductSection;