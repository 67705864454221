import React from 'react';
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from "react-helmet";  // Import Helmet
import clothingDesign from "../../assests/images/about-us-leftContent.png";
import traditionPic from "../../assests/images/Hanger.svg";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const AboutUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Helmet>
        {/* Primary SEO Tags */}
        <title>About Us - ArshyMualla</title>
        <meta 
          name="description" 
          content="Discover the unique blend of traditional eastern clothing and modern designs at Arshy Mualla. Explore high-quality women's tops and men's t-shirts that define style and comfort." 
        />
        <meta 
          name="keywords" 
          content="Arshy Mualla, eastern women's clothing, modern fashion, men's t-shirts, cultural elegance, stylish clothing, ecommerce fashion" 
        />
        
        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="About Us - ArshyMualla" />
        <meta 
          property="og:description" 
          content="Explore ArshyMualla's collection of eastern women's tops and modern men's t-shirts. High-quality, stylish, and comfortable clothing for every occasion." 
        />
        <meta property="og:url" content="https://www.arshymualla.com/aboutUs" />
        <meta property="og:type" content="website" />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.arshymualla.com/aboutUs" />

        {/* Additional SEO Optimizations */}
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Navbar />
      <Box sx={{ padding: '2rem', maxWidth: '1200px', margin: '0 auto' }}>
        <Typography variant="h4" align="center" gutterBottom  sx={{
          fontFamily: 'Frank Ruhl Libre',
          fontSize: { xs: 35, md: 60 },
          fontWeight: 900,
          lineHeight: { xs: '30px', md: '60px' },
          color: '#4A403A',
          letterSpacing: '2%',
        }}>
          About Us
        </Typography>
        
        <Grid container spacing={4} alignItems="center" mt={{xs: 0, md: 2}}>
          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="body1" align={isSmallScreen ? 'center' : 'left'}
            sx={{
              fontFamily: {xs: 'inherit', md:'Frank Ruhl Libre'},
              fontSize: { xs: 12, md: 20 },
              fontWeight: 500,
              lineHeight: { xs: '20px', md: '30px' },
              px: { xs: 2, md: 0 },
              color: '#4A403A',
              letterSpacing: '2%',
              textAlign: { xs: 'center', md: 'start' },
            }}>
              Welcome to Arshy Maula, where tradition meets contemporary fashion. We specialize in offering a curated collection of eastern women's tops, blending cultural elegance with modern designs, and trendy men's t-shirts that cater to your everyday style. Our mission is to provide high-quality, comfortable, and stylish clothing that empowers individuals to express their unique fashion. Each piece is crafted with attention to detail and a commitment to excellence. Join us in celebrating style, comfort, and tradition!
            </Typography>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <img
              src={traditionPic}
              alt="Eastern women's tops and men's t-shirts"  // Added alt text for SEO
              style={{ width: '100%', borderRadius: '70px' }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ marginTop: '2rem' }}>
          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <img
              src={clothingDesign}
              alt="Eastern clothing design"  // Added alt text for SEO
              style={{ width: '100%', borderRadius: '8px' }}
            />
          </Grid>

          {/* Vision Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align={isSmallScreen ? 'center' : 'left'}
            sx={{
              textAlign: "center",
              fontFamily: 'Frank Ruhl Libre',
              fontSize: { xs: 25, md: 35 },
              fontWeight: 900,
              lineHeight: { xs: '30px', md: '60px' },
              color: '#4A403A',
              letterSpacing: '2%',
            }}>
              Vision
            </Typography>
            <Typography variant="body1" align={isSmallScreen ? 'center' : 'left'}
            sx={{
              fontFamily: {xs: 'inherit', md:'Frank Ruhl Libre'},
              fontSize: { xs: 12, md: 20 },
              fontWeight: 500,
              lineHeight: { xs: '20px', md: '30px' },
              px: { xs: 2, md: 0 },
              color: '#4A403A',
              letterSpacing: '2%',
              textAlign: { xs: 'center', md: 'start' },
            }}>
              Our vision is to redefine elegance with simplicity. We aspire to create a fashion line that merges classic eastern designs with modern minimalism, offering clothing that makes you feel effortlessly stylish and comfortable. We believe true elegance lies in understated beauty, and our mission is to provide fashion that empowers individuals to express their uniqueness through timeless yet simple clothing choices, crafted with care and authenticity.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
